<template>
    <div :data-object-id="_dataObject?.id" :data-app-id="_dataObject?.appId" ref="containerEl" class="o365-data-object-container" tabindex="-1">
        <slot> :dataObject="_dataObject"</slot>
    </div>
</template>

<script setup lang="ts">
// Experimental, should not be used at the moment.
import type { DataObject } from 'o365-dataobject';
import { getDataObjectById } from 'o365-dataobject';
import { app } from 'o365-modules';
import { useEventListener } from 'o365-vue-utils';
import { ref, computed, provide, onMounted } from 'vue';

export interface IProps {
    dataObject?: DataObject,
    id?: string,
    appId?: string,
};

const props = defineProps<IProps>();

const containerEl = ref<HTMLElement>();

const _dataObject = computed(() => {
    if (props.dataObject) {
        return props.dataObject;
    } else if (props.dataObjectId) {
        const appId = props.appId ?? app.id;
        return getDataObjectById(props.dataObjectId, appId);
    } else {
        return undefined;
    }
});

provide('o365-data-object', _dataObject);

onMounted(() => {
    if (containerEl.value) {
        useEventListener(containerEl.value, 'keydown', (pEvent) => {
            if (pEvent.ctrlKey && ['s','S'].includes(pEvent.key)) {
                pEvent.preventDefault();
                pEvent.stopPropagation();
                _dataObject.value?.save();
            }
        });
    }
});
</script>

<style scoped>
.o365-data-object-container {
    display: contents;
}
</style>